import React,{lazy,Suspense,useState,useEffect} from 'react';
import {Box, Container, Divider, Typography,Grid } from '@mui/material';
import PaidIcon from '@mui/icons-material/Paid';
import {PricesInterface,PlanDataInterface} from "../Data/PlanPageData/PlanPageData";
import JoinLeftIcon from '@mui/icons-material/JoinLeft';
import { Element } from 'react-scroll';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';


// Lazy load components
const PlansSingleComponent = lazy(() => import('./Components/PlanPage/PlansSingleComponent'));
const StepByStep = lazy(() => import('./Components/PlanPage/StepByStep'));
const CurrencyCard = lazy(() => import('./ComponentsExports').then(module => ({ default: module.CurrencyCard })));


const Plans = () => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

    const [plansData, setPlansData] = useState<PlanDataInterface[]>([]);
    const [pricesData, setPricesData] = useState<PricesInterface[]>([]);

    useEffect(() => {
        const loadData = async () => {
            const { PlansAnnualData, PricesMonthlyData } = await import('../Data/PlanPageData/PlanPageData');
            console.log(PlansAnnualData)
            setPlansData(PlansAnnualData);
            setPricesData(PricesMonthlyData);
        };
        loadData();
    }, []);


    return (

    <div>
        <Container>
            <Box my={4}>
                <Typography sx={{ fontFamily: 'eBold', marginTop:'2rem', marginBottom:'2rem' }} variant="h3" component="h1" gutterBottom>
                    PLANS
                    <Divider textAlign={"left"}  sx={{fontFamily:"Thin",fontSize:"0.8rem"}} >PLANS SECTION</Divider>

                </Typography>
                <Typography sx={{ fontFamily: 'Reg' }} variant="h6">
                    Our Fees and due Fees are prepaid ( advanced payments only) for any service ( courses, our custom plan) on our page. Custom fee plans can be discussed with our administrative team after trial classes.
                     We offer weekend classes with flexible session lengths to suit your needs.
                    Our transparent pricing policy ensures proportionally adjusted fees.
                    We accept <span style={{fontFamily: 'eBold'}}>USD and GBP</span> payments, with flexible support for other currencies.
                    <span style={{fontFamily: 'eMed'}}><br/>
                    We offer personalized plans and financial assistance with impressive discounts (after case-by-case study).</span>
                    <span style={{fontStyle:"italic"}}><br/>Please keep in mind that Quran teaching is not charged the basis of course type but session time and frequency per week. </span>
                    <span style={{fontStyle:"italic"}}><br/>
                    Our primary goal is to share Quranic wisdom and make our programs inclusive and affordable.</span>
                </Typography>
            </Box>
        </Container>

        <Box className={"FormHeadingMain"}>
                    Flexible PLANS for Convenient eLearning
                <Typography
                    variant="body1"
                    sx={{ fontFamily: 'Thin', color: 'white',mt:2 }}
                >
                    Choose the perfect plans that suite your learning curve, schedule and budget.
                </Typography>
            </Box>

        <Container>
            <Grid container spacing={4}>
                <Grid item xs={12} md={8} order={{ xs: 2, md: 1 }}>
                    <Box my={4} textAlign="center">
                        <Typography sx={{ fontFamily:"eMed",marginTop:'2rem',}}  variant="h4" gutterBottom>
                            OUR ANNUAL <br/> PLANS <PaidIcon style={{ fontSize: 50, color: 'orange' }} />
                        </Typography>
                        <Divider />
                        <Box my={2}>
                            <Typography sx={{ fontFamily: 'Reg' }} variant="h6">
                                Mega Plans for Mega Success: <span style={{fontFamily:"eBold"}}>PLUS, PRO <span style={{fontFamily:"Reg"}}>and</span> ELITE</span> Options
                            </Typography>
                        </Box>
                    </Box>

                    {/* Lazy load PlansSingleComponent */}
                    <Suspense fallback={<div>Loading Annual Plans...</div>}>
                        {plansData.map((PlanData, index) => (
                            <PlansSingleComponent key={index} planData={PlanData} />
                        ))}
                    </Suspense>

                </Grid>

                <Grid item xs={12} md={4} order={{ xs: 1, md: 2 }}>
                    <Element name={"PlansAndPrices"}>
                    <Box my={4} textAlign="center">
                        <Typography sx={{ fontFamily:"eMed",marginTop:'2rem',}}  variant="h4" gutterBottom>
                               OUR MONTHLY PLANS <PaidIcon style={{ fontSize: 50, color: 'orange' }} />
                        </Typography>
                        <Divider />
                        <Box my={2}>
                            <Typography sx={{ fontFamily: 'Reg' }} variant="h6">
                                Our Monthly offerings
                            </Typography>
                        </Box>
                    </Box>
                    </Element>


                    <Suspense fallback={<div>Loading Monthly Plans...</div>}>
                        <div className="currency-cards-container">
                            {pricesData.map((price, index) => (
                                <CurrencyCard key={index} {...price} />
                            ))}
                        </div>
                    </Suspense>

                </Grid>
            </Grid>

    </Container>

        <Box my={4} mx={2}>
            <Typography sx={{ fontFamily: 'eBold', marginTop:'4rem', marginBottom:'2rem' }} variant="h3" component="h1" gutterBottom>
                REFUND NOTICE
            </Typography>
            <Typography sx={{ fontFamily: 'Reg', }} variant="h6">
                <span style={{fontFamily:"eThin", fontStyle:"italic"}}>The following is a short notice for refunds related queries.</span>
                <div style={{ textAlign: 'left', padding: isSmallScreen ? '0rem' : '0rem 4rem' }}>
                    <ul>
                        <li><span style={{fontFamily:"eBold"}}>Notified Absences</span> make-up classes can be scheduled.</li>
                        <li><span style={{fontFamily:"eBold"}}>No refunds</span> for unnotified absences and no make-up classes for them.</li>
                        <li>Refund requests will <span style={{fontFamily:"eBold"}}>not be entertained</span> if classes are canceled due to more than three unnotified absences. Student may risk cancellation of their course too.</li>
                        <li>Refunds won't be entertained for classes missed due to <span style={{fontFamily:"eBold"}}>Eid holidays, government or international holidays</span> as they are considered paid holidays.</li>
                        <li>Refunds will be handled within <span style={{fontFamily:"eBold"}}>14 business days (no holidays counted)</span> after the request has been approved by the admin of Luminary Quran.</li>
                    </ul>
                </div>
                <span style={{fontFamily:"eThin", fontStyle:"italic"}}>More in-depth refund policy see refund policy under our policy</span>
            </Typography>
        </Box>

        <Element name="JoiningGuideSection">
        <Typography sx={{ fontFamily:"eMed",marginTop:'2rem',}}  variant="h4" gutterBottom>
            ENROLLMENT STRUCTURE  <JoinLeftIcon style={{ fontSize: 50, color: 'orange' }}/>
        </Typography>
        </Element>
        <Divider />

        {/* Lazy load StepByStep component */}
        <Suspense fallback={<div>Loading StepByStep Guide...</div>}>
            <StepByStep />
        </Suspense>

        <Divider textAlign={"right"}  sx={{fontFamily:"Thin",fontSize:"0.8rem", mt:"4rem"}} > OUR PLANS</Divider>
    </div>
    );
};

export default Plans;
