import React, { Suspense, lazy,useState,useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Skeleton from '@mui/material/Skeleton';
import { Element, ScrollToTop } from './Pages/ComponentsExports';
import './App.css';
import "../src/Global.scss";
import logo from './Multimedia/Logo/MainLogo.webp';
import {Home} from "./Pages/ComponentsExports"

// Lazy load components
const Navbar0 = lazy(() => import('./Pages/ComponentsExports').then(module => ({ default: module.Navbar0 })));
const Navbar1 = lazy(() => import('./Pages/ComponentsExports').then(module => ({ default: module.Navbar1 })));
const Footer = lazy(() => import('./Pages/ComponentsExports').then(module => ({ default: module.Footer })));
const FloatingWhatsApp = lazy(() => import('react-floating-whatsapp').then(module => ({ default: module.FloatingWhatsApp })));
const RegistrationForm = lazy(() => import('./Pages/Components/FormComponent/Form'));


// Lazy load routes
// const Home = lazy(() => import('./Pages/ComponentsExports').then(module => ({ default: module.Home })));
const Courses = lazy(() => import('./Pages/ComponentsExports').then(module => ({ default: module.Courses })));
const Plans = lazy(() => import('./Pages/ComponentsExports').then(module => ({ default: module.Plans })));
const OurTeam = lazy(() => import('./Pages/ComponentsExports').then(module => ({ default: module.OurTeam })));
const OurPolicy = lazy(() => import('./Pages/ComponentsExports').then(module => ({ default: module.OurPolicy })));
const Testimonials = lazy(() => import('./Pages/ComponentsExports').then(module => ({ default: module.Testimonials })));
const AboutUs = lazy(() => import('./Pages/ComponentsExports').then(module => ({ default: module.AboutUs })));

// Fallback UI components
const NavbarSkeleton: React.FC = () => (
    <Skeleton variant="rectangular" width="100%" height={64} animation="wave" />
);

const FooterSkeleton: React.FC = () => (
    <Skeleton variant="rectangular" width="100%" height={200} animation="wave" />
);

const FloatingWhatsAppSkeleton: React.FC = () => (
    <Skeleton variant="circular" width={60} height={60} animation="wave" style={{ position: 'fixed', bottom: 20, right: 20 }} />
);

const PageSkeleton: React.FC = () => (
    <Skeleton variant="rectangular" width="100%" height="calc(100vh - 64px)" animation="wave" />
);

const App: React.FC = () => {
    const [homeLoaded, setHomeLoaded] = useState<boolean>(false);
    const backgroundGradient = `linear-gradient(90deg, #CC3700 0%, #D94A00 15%, #E68000 30%, #E69500 45%, #E6A100 50%, #E69500 55%, #E68000 70%, #D94A00 85%, #CC3700 100%)`;

    const handleHomeLoaded = () => {
        setHomeLoaded(true);
    };

    return (
        <Router>
            <header className="bg-gray-500 flex"></header>
            <div className="App">
                <Suspense fallback={<NavbarSkeleton />}>
                    <Navbar0 backgroundGradient={backgroundGradient} />
                </Suspense>

                <Suspense fallback={<NavbarSkeleton />}>
                    <Navbar1 />
                </Suspense>

                <div>
                    <Suspense fallback={<FloatingWhatsAppSkeleton />}>
                        <FloatingWhatsApp
                            phoneNumber="+17166576165"
                            accountName="LuminaryQuran"
                            avatar={logo}
                            statusMessage="We Typically respond as soon as possible."
                            chatMessage="Assalamualaikum Warahmatullahi Wabarakatuh! We are committed helping you enhance your Quranic knowledge and spiritual journey. Let us know how we can support you at every step."
                            placeholder="Type a message.."
                            messageDelay={2}
                            darkMode={true}
                            allowClickAway={true}
                            allowEsc={true}
                            chatboxHeight={420}

                        />
                    </Suspense>
                </div>

                <ScrollToTop />
                <Suspense fallback={<PageSkeleton />}>
                <Routes>

                    <Route path="/" element={<Home onHomeLoaded={handleHomeLoaded} />} />

                    {homeLoaded && (
                        <>
                            <Route path="/Courses" element={<Courses />} />
                            <Route path="/Plans" element={<Plans />} />
                            <Route path="/OurTeam" element={<OurTeam />} />
                            <Route path="/OurPolicy" element={<OurPolicy />} />
                            <Route path="/Testimonials" element={<Testimonials />} />
                            <Route path="/AboutUs" element={<AboutUs />} />
                        </>
                    )}

                </Routes>
                </Suspense>

                <Suspense fallback={<Skeleton variant="rectangular" width="100%" height={400} />}>
                    <div className="FormHeadingMain" style={{margin: "3rem 0rem 0rem 0rem" }}>Become a Member of <br/> Luminary Quran</div>
                    <Element name="XXXX">
                        <RegistrationForm />
                    </Element>
                </Suspense>

                <Suspense fallback={<FooterSkeleton />}>
                    <Footer />
                </Suspense>
            </div>
        </Router>
    );
}

export default App;